import React, { useEffect, useState } from 'react';
import {
  Card, Row, Col, Form, Alert,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { getGeneratorById, updateGenerator, getFormQuestionsByFormType } from 'api/BroadcastFormAPI';
import { EnterpriseBroadcastFormGeneratorModelEdit } from 'types/BroadcastForm';
import { Checkbox, Input, Select } from 'components/FormControls';
import { Helmet } from 'react-helmet';
import { selectBroadcastFormTypes } from 'reducers/UserInfo';
import { useSelector } from 'react-redux';

const UpdateBroadcastFormGenerator: React.FC = () => {
  const { generatorId } = useParams<{ generatorId: string }>();
  const history = useHistory();
  const {
    handleSubmit, control, formState: { errors }, watch, reset,
  } = useForm<EnterpriseBroadcastFormGeneratorModelEdit>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const broadcastFormTypeOptions = useSelector(selectBroadcastFormTypes);
  const formType = watch('FormType');
  const swrKey = ['getmultiaccountsurveyquestions', formType];
  const { data: broadcastFormQuestions, isLoading } = useSWR(
    swrKey,
    () => getFormQuestionsByFormType(formType),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  const { data: generator, isLoading: getGeneratorLoading, error: fetchError } = useSWR(
    ['EbfGenerator', generatorId],
    async () => {
      const response = await getGeneratorById(parseInt(generatorId, 10), {
        ignoreGlobalCatch: true,
      });
      return response;
    },
    {
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (generator) {
      reset({
        SurveyName: generator.SurveyName,
        Description: generator.Description || '',
        IsActive: generator.IsActive,
        SendToStaff: generator.SendToStaff,
        SendToResidents: generator.SendToResidents,
        SendToFamily: generator.SendToFamily,
        SendEmail: generator.SendEmail,
        SendSms: generator.SendSms,
        FormType: generator.FormType,
      });
    }
  }, [generator, getGeneratorLoading, reset]);

  const onSubmit = async (formData: EnterpriseBroadcastFormGeneratorModelEdit) => {
    if (!formData.SendToStaff && !formData.SendToResidents && !formData.SendToFamily) {
      setError('At least one recipient group must be selected.');
      return;
    }

    if (!formData.SendEmail && !formData.SendSms) {
      setError('At least one channel (Email or SMS) must be selected.');
      return;
    }

    setLoading(true);
    try {
      const updatedGenerator = await updateGenerator(parseInt(generatorId, 10), formData);
      history.push('/internal/multiaccountsurvey');
      toast.success(`Survey Generator "${updatedGenerator.SurveyName}" successfully updated`);
    } catch (ex) {
      setError('Failed to update the broadcast form generator.');
    } finally {
      setLoading(false);
    }
  };

  if (fetchError) {
    return <Alert variant="danger">Failed to load the broadcast form generator.</Alert>;
  }

  if (!generator || isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="EbfGeneratorEdit">
      <Helmet>
        <title>Update Multi-Account Survey Generator</title>
      </Helmet>
      <div className="header-bar">
        <h1>Update Multi-Account Survey Generator</h1>
      </div>
      <Card className="overflow-visible flex-direction-row">
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Input
                    id="SurveyName"
                    control={control}
                    type="Text"
                    name="SurveyName"
                    label="Survey Name"
                    required
                    rules={{
                      required: 'Survey Name is required',
                      maxLength: {
                        value: 50,
                        message: 'Survey Name must be 50 characters or less',
                      },
                      validate: (val: string) => {
                        if (val.trim() === '') {
                          return 'Survey Name cannot be empty or whitespace'
                        }
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Survey Name can not include emojis';
                        }
                        return true;
                      },
                    }}
                    maxLength={50}
                    errors={errors.SurveyName?.message}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Select
                    id="FormType"
                    name="FormType"
                    label="Survey"
                    placeholder="Select content for survey"
                    required
                    control={control}
                    options={broadcastFormTypeOptions}
                    errors={errors.FormType?.message}
                    rules={{
                      required: 'Survey type is required',
                    }}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Input
                    id="Description"
                    control={control}
                    type="Text"
                    name="Description"
                    label="Description (optional)"
                    maxLength={600}
                    rules={{
                      maxLength: {
                        value: 600,
                        message: 'Description must be 600 characters or less',
                      },
                      validate: (val: string) => {
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Description can not include emojis';
                        }
                        return true;
                      },
                    }}
                    errors={errors.Description?.message}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Active:</h4>
                  <Checkbox
                    id="IsActive"
                    name="IsActive"
                    className="ml-4"
                    label="Is Active"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Recipients:</h4>
                  <Checkbox
                    id="SendToStaff"
                    name="SendToStaff"
                    className="ml-4"
                    label="Send to Staff"
                    control={control}
                  />
                  <Checkbox
                    id="SendToResidents"
                    name="SendToResidents"
                    className="ml-4"
                    label="Send to Residents"
                    control={control}
                  />
                  <Checkbox
                    id="SendToFamily"
                    name="SendToFamily"
                    className="ml-4"
                    label="Send to Family"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Channels:</h4>
                  <Checkbox
                    id="SendEmail"
                    name="SendEmail"
                    className="ml-4"
                    label="Email"
                    control={control}
                  />
                  <Checkbox
                    id="SendSms"
                    name="SendSms"
                    className="ml-4"
                    label="SMS"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>
            <button type="submit" className="btn btn-primary mt-4 mb-4" disabled={loading}>
              {loading ? 'Updating...' : 'Update Generator'}
            </button>
          </form>
        </Card.Body>

        <Card.Body className="questions-card">
          {(isLoading || broadcastFormQuestions == null) && (
            <div
              className="loading-text d-flex align-items-center justify-content-center"
              role="status"
            >
              <Spinner
                animation="border"
                as="span"
                className="mr-2"
                style={{ width: '1.66em', height: '1.66em' }}
              />
              <span style={{ fontSize: '1.25em' }}>
                Loading...
              </span>
            </div>
          )}
          {!isLoading && (
            <div style={{ height: '628.5px', overflowY: 'auto' }}>
              <p>
                Survey Questions (
                {broadcastFormQuestions?.length}
                {' '}
                total)
              </p>
              <ListGroup>
                {broadcastFormQuestions.map((prof) => (
                  <ListGroup.Item
                    key="1"
                    data-testid="message-recipient"
                    className="tableListItem"
                  >
                    {' '}
                    {prof.QuestionText}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UpdateBroadcastFormGenerator;
