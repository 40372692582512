import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import useSWR from 'swr';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { getAllProfiles } from 'reducers/Profiles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import SelectInput from 'components/Inputs/Select';
import useSWRMutation from 'swr/mutation';
import { toast } from 'react-toastify';

export default function FillOutSurveyPage(): ReactElement {
  const { formId } = useParams<{ formId?: string }>();
  const dispatch = useDispatch();
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [profileOptions, setProfileOptions] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const defaultProfileOption = { value: '', label: 'No Profile Selected' };
  const swrKey = ['getbroadcastformresentbyid', formId];
  const { data, isLoading } = useSWR(
    swrKey,
    () => BroadcastFormApi.getFormResendById(formId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });
  const swrCheckProfileResponseKey = ['checkprofileresponse', formId, selectedProfileId];
  const { data: hasResponse, isLoading: isChecking, mutate } = useSWR(
    swrCheckProfileResponseKey,
    () => BroadcastFormApi.checkProfileHasResponse(formId, selectedProfileId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (hasRespone: boolean) => {
        if (!selectedProfileId || selectedProfileId === '') {
          setButtonDisabled(true);
        } else {
          setButtonDisabled(hasRespone);
        }
      },
    });
  const addBroadcastFormRecipient = async (url, { arg }) => {
    const newFormSessionId = await BroadcastFormApi.addRecipient(formId, selectedProfileId);
    return newFormSessionId;
  }
  const { trigger: addRecipient } = useSWRMutation('addBroadcastFormRecipient', addBroadcastFormRecipient, {
    revalidate: true,
    populateCache: false,
    onSuccess: (sessionId: string) => {
      window.open(`/form/${formId}?id=${sessionId}`, '_blank')
      setSelectedProfileId(null);
      setButtonDisabled(true);
    },
  });

  const allprofilesbyids = useSelector((state: RootState) => state.Profiles.byIds);
  const profileAllIds = useSelector((state: RootState) => state.Profiles.allIds);
  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading);

  useEffect(() => {
    dispatch(getAllProfiles());
  }, [dispatch]);
  useEffect(() => {
    if (!profileAllIds.length) {
      return;
    }
    const options = [defaultProfileOption];
    profileAllIds
      .map((cpid) => {
        const profile = allprofilesbyids[cpid];
        if (profile) {
          options.push({
            value: profile.CustomerProfileID,
            label: `${profile.FirstName} ${profile.LastName}`,
          })
        }
      });
    setProfileOptions(options);
  }, [profilesLoading, allprofilesbyids]);

  const onAddSurveyResponse = async () => {
    setButtonDisabled(true);
    try {
      addRecipient();
    } catch (error) {
      toast.error(error.response?.data?.Message ?? 'Something went wrong');
    }
  }

  return (
    <div className="TemplatesEdit">
      {(isLoading || profilesLoading || !data) && (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      )}
      {(!isLoading && !profilesLoading && data !== null
        && (
          <>
            <Helmet>
              <title>{data.Name}</title>
            </Helmet>
            <div className="header-bar">
              <h1>{data.Name}</h1>
            </div>
            <p>{data.Description}</p>
            <form>
              <Container fluid>
                <br />
                <Row>
                  <Col xs={12} md={6}>
                    <SelectInput
                      id="Profile"
                      name="Profile"
                      value={selectedProfileId}
                      defaultValue=""
                      label="Select the profile to add their response"
                      className="selectInputPaddingRight"
                      options={profileOptions}
                      required
                      onChange={(value) => {
                        setSelectedProfileId(value);
                        if (value === '' || value === null) {
                          setButtonDisabled(true);
                        } else {
                          mutate();
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {isChecking && selectedProfileId && (
                      <div
                        className="loading-text d-flex align-items-center justify-content-center"
                        role="status"
                      >
                        <Spinner
                          animation="border"
                          as="span"
                          className="mr-2"
                          style={{ width: '1.66em', height: '1.66em' }}
                        />
                        <span style={{ fontSize: '1.25em' }}>
                          Loading...
                        </span>
                      </div>
                    )}
                    {!isChecking && selectedProfileId && hasResponse && (
                      <p><span className="errors">A survey response for this recipient has already been recorded</span></p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button
                      type="button"
                      className="btn btn-primary square mb-0"
                      disabled={buttonDisabled}
                      onClick={onAddSurveyResponse}
                    >
                      Add Survey Response
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          </>
        )
      )}

    </div>
  );
}
